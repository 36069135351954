<template>
	<div class="project-overview-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Sales Analytics</div>
			<v-spacer></v-spacer>
			<v-btn-toggle v-model="filter_value" borderless depressed dense tile>
				<v-btn value="Today">
					<span class="">Today</span>
				</v-btn>
				<v-btn value="Weekly">
					<span class="">Weekly</span>
				</v-btn>
				<v-btn value="monthly">
					<span class="">Monthly</span>
				</v-btn>
			</v-btn-toggle>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="line"
					height="450"
					:options="chartOptions"
					:series="series"
					width="100%"
				></apexchart>
			</div>
			<div class="project-desc">
				<ul class="pa-0 ul">
					<li class="d-flex li" v-for="(row, index) in chartData" :key="index">
						<div class="d-flex">
							<div :class="`status_color mr-3 ${row.status_color}`"></div>
							<div class="name text-truncate">{{ row.status }}</div>
						</div>
						<div class="project-number text-right">{{ row.order_no }}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			filter_value: null,
			series: [
				{
					name: "Sales",
					type: "column",
					data: [23, 11, 42, 27, 43, 22, 37, 21, 44, 22, 30, 12],
				},
				{
					name: "Revenue",
					type: "line",
					data: [44, 55, 41, 67, 62, 43, 41, 71, 56, 47, 43, 33],
				},
			],
			chartData: [],
			chartOptions: {
				chart: {
					height: 350,
					type: "line",
				},
				stroke: {
					width: [0, 4],
				},
				title: {
					text: "Traffic Sources",
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
				},
				plotOptions: {
					bar: {
						columnWidth: "20%",
					},
				},
				labels: [
					"01 Jan 2001",
					"02 Jan 2001",
					"03 Jan 2001",
					"04 Jan 2001",
					"05 Jan 2001",
					"06 Jan 2001",
					"07 Jan 2001",
					"08 Jan 2001",
					"09 Jan 2001",
					"10 Jan 2001",
					"11 Jan 2001",
					"12 Jan 2001",
				],
				xaxis: {
					type: "datetime",
				},
				yaxis: [
					{
						title: {
							text: "Net Revenue",
						},
					},
					{
						opposite: true,
						title: {
							text: "No of sales",
						},
					},
				],
			},
		};
	},
};
</script>
